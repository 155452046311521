import * as React from "react"
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from '../components/layout'
import SEO from '../components/SEO'

const IndexPage = () => {
  const data = useStaticQuery(
    graphql`
    query {
      wpPage(slug: {eq: "home"}) {
        content
        featuredImage {
          node {
            localFile {
              childImageSharp {
                fluid(quality: 90, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
                gatsbyImageData(
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    }
    `
  )
  const content = data.wpPage.content
  const imageData = data.wpPage.featuredImage.node.localFile.childImageSharp.fluid
  const image = data.wpPage.featuredImage.node.localFile.childImageSharp.gatsbyImageData

  return (
    <>
      <SEO title='Consult, Construct and Create' />
      <Layout {...{ content, imageData }}>
        <GatsbyImage image={image} className='home_image' objectFit='cover' />
      </Layout>
    </>
  )
}

export default IndexPage
